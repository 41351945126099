@keyframes latido {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  
  .latido {
    animation: latido 0.5s infinite; 
  }
  