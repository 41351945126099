.ingreso-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .ingreso-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .codigo-barra-input {
    display: flex;
    align-items: center;
  }
  
  .codigo-barra-input input {
    flex: 1;
    margin-right: 10px;
  }
  
  .btn-scan,
  .btn-clear,
  .btn-submit {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .btn-clear {
    background-color: #dc3545;
    margin-left: 10px;
  }
  
  .btn-submit {
    width: 100%;
    margin-top: 20px;
  }
  
  .btn-scan:hover,
  .btn-clear:hover,
  .btn-submit:hover {
    background-color: #0056b3;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
  }
  
  .btn-close {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #dc3545;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
  }
  
  .btn-close:hover {
    background-color: #c82333;
  }
  .form-ingreso{
    margin-top: 20px; 
    margin-bottom: 120px;
 }