.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .login-box {
    background-color: white;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .login-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
  }
  
  .login-button:hover {
    background-color: #45a049;
  }
  
  .register-text {
    margin-top: 20px;
  }
  
  .register-button {
    background-color: #2196f3;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
  }
  
  .register-button:hover {
    background-color: #0b7dda;
  }
  
  .error-message {
    color: red;
    margin-bottom: 20px;
  }
  