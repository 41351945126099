.botoneraintferior{
    background-color: #F3F4F6;
}

.botoneraintferior a {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.botoneraintferior a span {
    display: block;
    line-height: 1.2;
    margin-top: 4px;
}

.botoneraintferior a svg {
    display: block;
    margin: 0 auto;
}


@keyframes latido {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  
  .latido {
    animation: latido 0.5s infinite; 
  }
  